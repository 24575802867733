import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import { useState } from "react";
import CountUp from 'react-countup';
import { useRef } from "react";
import { Outlet } from "react-router-dom";
import Scheduler from "./scheduler";
import ReactGA from "react-ga4";
import { analyticsCall } from "../../helpers/analytics";

const Contact = () => {

    const [scheduler, setScheduler] = useState(false);
    const navigate = useNavigate()


    return (
        <>


            {
                scheduler && <div className="modal-window-container">
                    <style>
                        {
                            `
                            body {
                                overflow: hidden;
                            }
                            `
                        }
                    </style>
                    <button onClick={
                        () => setScheduler(false)
                    } className="modal-overlay-close"></button>
                    <div className="modal-window vivify fadeIn duration-400">
                        <div className="modal-close-container">
                            <button onClick={
                                () => setScheduler(false)
                            } classNa me="modal-close">
                                <i className="material-icons-round">close</i>
                            </button>
                        </div>

                        <Scheduler />

                    </div>

                </div>
            }
            <div className="contact-container">
                <div className="core-header">
                    <span>Contact Us Today</span>
                    <h1>Get a Free Quote</h1>

                </div>


                <div style={{ marginTop: 0, height: 350 }} className="chunker-content">
                    <div className="group-card">
                        <img src="/assets/video.png" />
                        <div className="group-card-content">
                            <div className="group-card-text">
                                <span>
                                    Based on Availability
                                </span>
                                <h3>Book a Consultation</h3>
                                <p>Powered By Google Calendar</p>
                            </div>
                            <Link to={'https://calendar.app.google/H3EpStyr35E4ffd66'} target="_blank" onClick={() => {
                                analyticsCall({
                                    category: "Contact Ribbon - Click",
                                    action: "Schedule Now",
                                })

                            }} className="button-static">
                                Schedule Now
                            </Link>

                        </div>
                    </div>
                    <span className="chunklet-divider"></span>
                    <div className="group-card">
                        <img src="/assets/phone.png" />
                        <div className="group-card-content">
                            <div className="group-card-text">
                                <span>
                                    8am - 5pm CST (Mon - Sat)
                                </span>
                                <h3>Call Us Today</h3>
                                <p>(601) 844-1026</p>
                            </div>
                            <Link onClick={
                                () => {
                                    analyticsCall({
                                        category: "Contact Ribbon - Click",
                                        action: "Call Now",
                                    })
                                }
                            } to="tel:601 844-1026" className="button-static">
                                Call Now
                            </Link>
                        </div>
                    </div>
                    <span className="chunklet-divider"></span>
                    <div className="group-card">
                        <img src="/assets/mail.png" />
                        <div className="group-card-content">
                            <div className="group-card-text">
                                <span>
                                    8am - 5pm CST (Mon - Sat)
                                </span>
                                <h3>Email Us</h3>
                                <p>Inbound@FlybyShine.com</p>
                            </div>
                            <Link onClick={
                                () => {
                                    analyticsCall({
                                        category: "Contact Ribbon - Click",
                                        action: "Email Now",
                                    })
                                }
                            } to="mailto:Inbound@FlybyShine.com" className="button-static">
                                Email Now
                            </Link>
                        </div>
                    </div>


                </div>
            </div>


        </>
    );
}

export default Contact